import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { navigate } from "gatsby"

export default function PostRow({
  title,
  tag,
  slug,
  date,
  description,
  excerpt,
  image,
}) {
  const onClick = () => {
    navigate(slug)
  }

  const formattedDate = new Date(date).toLocaleDateString("en-UK", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  })

  return (
    <div className="post-row" onClick={onClick}>
      <span className="post-card-date">{formattedDate}</span>
      <div className="post-row-title">{title}</div>
      <div className="post-row-excerpt">{description}</div>
      <div>
        {tag.split(" ").map(t => (
          <span className="post-card-tag mr5">{t}</span>
        ))}
      </div>
    </div>
  )
}
